/* eslint-disable complexity */
import {User} from '@hconnect/apiclient/src'
import queryString from 'query-string'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'

import {withRouteRequiringPermission} from '../../../Molecules/PermissionBoundary'
import {ResponsivePageHeader} from '../../../Molecules/ResponsivePage/Header'
import {ResponsivePage} from '../../../Molecules/ResponsivePage/ResponsivePage'
import {selectSelectedCustomer} from '../../../Organisms/Customers'
import {Customer} from '../../../Organisms/Customers/Customers.types'
import {useFeaturesState} from '../../../Organisms/Features'
import {PermissionTypes} from '../../../Permissions'
import {AppState} from '../../../Root.store'
import {Delivery} from '../../Finance/Invoices/Delivery.types'
import {useDeliveriesByOrderId} from '../hooks'
import {ROUTE as OrderRoute} from '../index'
import {useAllFeaturesOrderItems} from '../Order.utils'

import {DeliveriesTable} from './components/DeliveriesTable'
import {DeliveryDetails} from './components/DeliveryDetails'
import {OrderDetails} from './components/OrderDetails'
import {SideOrderDetails} from './components/SideOrderDetails'
import {useOrder} from './hooks'

const OrderDetail: React.FC = () => {
  const {t} = useTranslation()
  const {customerId} = queryString.parse(window.location.search)
  const {haulierId} = queryString.parse(window.location.search)
  const {id: groupId} = useParams<{id: string}>()
  const user = useSelector<AppState, User | null>((state) => state.userProfile.userProfile)
  const [selectedDelivery, setSelectedDelivery] = React.useState<Delivery | undefined>(undefined)

  const {getFeature} = useFeaturesState()
  const {
    isGroupedDeliveries,
    isPumpServiceIncluded,
    isServiceOrderIncluded,
    isFilteringOutCancelledDeliveries,
    includeDeliveryInstructions,
    expandToOrderDocuments,
    useHaulierView
  } = useAllFeaturesOrderItems()

  const resolvedCustomerId = useHaulierView ? haulierId : customerId

  const selectedCustomer = useSelector<AppState, Customer | undefined>((state: AppState) =>
    selectSelectedCustomer(state, resolvedCustomerId)
  )

  const includeOrderChanges = getFeature('OrderChangeTrail')
  const {
    isFetching: isOrderFetching,
    data: order,
    isError: isOrderError
  } = useOrder({
    groupId,
    customerId: resolvedCustomerId as string | undefined,
    isGroupedDeliveries,
    useHaulierView,
    isPumpServiceIncluded,
    includeOrderChanges,
    isServiceOrderIncluded,
    excludeCancelledOrders: isFilteringOutCancelledDeliveries,
    includeDeliveryInstructions,
    expandToOrderDocuments
  })

  const {
    isFetching: isDeliveriesFetching,
    data: deliveries,
    isError: isDeliveriesError
  } = useDeliveriesByOrderId(
    resolvedCustomerId as string,
    groupId,
    isGroupedDeliveries,
    isFilteringOutCancelledDeliveries,
    order?.orderIds,
    isPumpServiceIncluded,
    useHaulierView,
    'deliveryNumber',
    'desc'
  )

  const isFetching = isOrderFetching || isDeliveriesFetching || !resolvedCustomerId
  const isError = isOrderError || isDeliveriesError

  const analytics = {
    userId: user?.user_id,
    userMail: user?.eMail,
    userCountry: user?.country,
    userRoles: user?.hasRoles,
    userIsInternal: user?.isInternal,
    customerId: selectedCustomer?.customerId,
    customerName: selectedCustomer?.customerName,
    downloadedItemBusinessLine: order?.businessLine,
    salesOrg: order?.orgUnitId,
    orderId: order?.orderId,
    details: true
  }

  const header = (
    <ResponsivePageHeader
      title={t('order.details')}
      backNavigation={t('order.listOfOrders')}
      backNavigationDataTestId="order-details-back-button"
    />
  )

  const mainContent = (
    <>
      {isError && t('error.server')}
      <OrderDetails order={order} deliveries={deliveries} isFetching={isFetching} />
      <DeliveriesTable
        order={order}
        deliveries={deliveries}
        customerId={resolvedCustomerId as string}
        analytics={analytics}
        setSelectedDelivery={setSelectedDelivery}
        isFetching={isDeliveriesFetching}
      />
    </>
  )

  const sideContent = (
    <SideOrderDetails
      order={order}
      customerId={customerId as string}
      haulierId={haulierId as string}
      useHaulierView={useHaulierView}
      isFetching={isOrderFetching}
    />
  )

  const overlayContent = selectedDelivery && order && (
    <DeliveryDetails
      delivery={selectedDelivery}
      order={order}
      close={() => setSelectedDelivery(undefined)}
      analytics={analytics}
      customerId={resolvedCustomerId as string}
    />
  )

  return (
    <ResponsivePage
      header={header}
      mainContent={mainContent}
      sideContent={sideContent}
      overlayContent={overlayContent}
    />
  )
}

const ROUTE = `${OrderRoute}/:id`

// eslint-disable-next-line import/no-default-export
export default withRouteRequiringPermission({
  path: ROUTE,
  hideInMainNav: true,
  routeProps: {exact: true},
  label: 'Order Details',
  permissions: [PermissionTypes.VIEW_ORDERS_DELIVERIES, PermissionTypes.VIEW_ALL_DATA]
})(OrderDetail)
